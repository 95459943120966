import { makeAutoObservable } from 'mobx'
import { hydrateStore } from 'mobx-persist-store'

export class Exam {
  private examStats: IExamStats | null = null

  public getExamStats() {
    return this.examStats
  }

  public setExamStats(stats: IExamStats) {
    this.examStats = stats
  }

  constructor() {
    makeAutoObservable(this)
  }

  hydrate = async () => {
    await hydrateStore(this)
  }
}
