import { makeAutoObservable } from 'mobx'
import { hydrateStore, makePersistable } from 'mobx-persist-store'

export class Admin {
  public id: string = ''
  public name: string = ''
  public email: string = ''
  public phone: string = ''
  public role: any = { id: '', name: '', permissions: [] }

  constructor(admin: any) {
    this.id = admin.id
    this.name = admin.name
    this.email = admin.email
    this.phone = admin.phone
    this.role = admin.role

    makeAutoObservable(this)

    // makePersistable(this, {
    //   name: 'Admin',
    //   properties: ['id', 'name', 'email', 'phone', 'role'],
    // })
  }

  hydrate = async () => {
    await hydrateStore(this)
  }
}
