import { makeAutoObservable } from 'mobx'
import { hydrateStore, makePersistable } from 'mobx-persist-store'

import { adminEmailAndPasswordLoginApiMethod } from '@/services/api/auth'
import { setToken } from '@/lib/helpers'

import { Admin } from './models/admin'
import { stores } from '.'
import { OrganizationModel } from './models/organization'

export class Auth {
  public currentAdmin: Admin | null = null
  public currentOrganization: OrganizationModel | undefined = undefined
  public organizations: any[] = []

  public async setCurrentAdmin(admin: any) {
    if (admin) {
      this.currentAdmin = new Admin(admin)
    } else {
      this.currentAdmin = null
    }
  }

  public async setOrganizations(organizations: any[]) {
    this.organizations = organizations
  }


  public async setCurrentOrganization(organization: any) {
    if (organization) {
      this.currentOrganization = new OrganizationModel(organization)
    } else {
      this.currentOrganization = undefined
    }
  }

  public async adminEmailAndPasswordLogin(params: {
    email: string
    password: string
  }) {
    try {
      const res = await adminEmailAndPasswordLoginApiMethod(params)

      const token = res.data.data.token
      const admin = res.data.data.admin
      const organization = res.data.data.organization
      const organizations = res.data.data.organizations
      const role = res.data.data.role

      if (organizations && organizations.length > 1) {
        this.setOrganizations(organizations)
      }

      if (organization && organization.id) {
        // Prevent Access for STLB Staff except their admin and a staff
        // if (organization.id === 'bd5fc2db-3378-45e7-a706-12c9013806f7' && !['sholafolorunsho0@gmail.com', 'stlouisschoolbida@yahoo.com'].includes(admin?.email)) {
        //   throw new Error("Account doesn't exists.")
        // }
        this.setCurrentOrganization(organization)
      } else {
        this.setCurrentOrganization(null)
      }

      document.cookie = 'authtoken=' + token
      setToken(token)

      this.setCurrentAdmin({ ...admin, role })
    } catch (error) {
      throw error
    }
  }

  public switchOrganization(organizationId: string) {

    const organization = this.organizations.find(
      (org) => org.admin?.organization?.id === organizationId
    )
    if (organization) {
      this.setCurrentOrganization(organization.admin?.organization)

      document.cookie = 'authtoken=' + organization.token
      setToken(organization.token)
    }
  }



  public logoutAdmin() {
    document.cookie =
      'authtoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    setTimeout(() => {
      stores.auth.setCurrentAdmin(null)
      stores.auth.setCurrentOrganization(null)
      stores.auth.setOrganizations([])
    }, 2000)
  }

  public logoutCandidate() { }

  public getMyPermissions() {
    return this.currentAdmin?.role.permissions
  }

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'Auth',
      properties: ['currentAdmin', 'currentOrganization', 'organizations'],
    })
  }

  hydrate = async () => {
    await hydrateStore(this)
  }
}
