import Head from 'next/head'

import { APP_NAME } from '@/lib/constants/appConstants'

const Meta = ({
  title,
  keywords,
  description,
  ogTitle,
  ogDescription,
  ogType,
  ogUrl,
  ogImage,
}: MetaProps) => {
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <meta name="keywords" content={keywords}></meta>
      <meta name="description" content={description}></meta>
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content={ogImage} />
      <meta charSet="utf-8"></meta>
      <link rel="icon" href="/favicon.ico"></link>
      <title>
        {title}
        {title?.includes(APP_NAME) ? '' : ` - ${APP_NAME}`}
      </title>
    </Head>
  )
}
Meta.defaultProps = {
  title:
    APP_NAME +
    ' - Online Exam Software to Create Exams | Online Tests | Test Creation & Do Exam | Exam Analysis | Exam Live Monitoring',
  keywords:
    'Online Exam Software, Online Examination System, Online Examination Software, Online Exam Platform, Online Testing Software, Online Exam Solutions, Online Test, Test Creation Software, Online Assessment Platform, Online Assessment Software, Online Proctoring Software, Online Live Monitoring, Computer Based Examination, Online Examination, Online Exam, Nigeria Online Exam System, Exam Online Live Monitoring in Nigeria, Online Exam Live Monitoring, Test Live Monitoring, Test Live Monitoring in Nigeria, Nigeria Online Test, Nigeria Online Exam',
  description:
    APP_NAME +
    ' is the most trusted and widely accepted online exam software. Use this platform to create online test, allocate assignments, generate scorecards and share reports.',
  ogTitle:
    'Create Online Exams | Online Tests | Conduct Online Exam | Exam Live Monitoring - testnotion.com',
  ogDescription:
    APP_NAME +
    ' is the most trusted and secure online examination platform. You can assign and create online exams, tests, quizzes and monitor live with advanced features.',
  ogUrl: 'https://www.testnotion.com',
  ogType: 'Website',
  ogImage: 'https://www.testnotion.com/assets/logo/logo-primary-250x150.png',
}
export default Meta
