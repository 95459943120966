import { createStandaloneToast } from '@chakra-ui/react'
import axios from 'axios'
import Router from 'next/router'
import { omitBy, isNil } from 'lodash'
import localforage from 'localforage'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import io from 'socket.io-client'

import theme from '../theme'
import { ADMINS_ID, CANDIDATE_ID, L_TOKEN } from './constants'
import { PAGES_DATA } from './constants/pagesData'
import { stores } from '../stores'

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

const customToast = createStandaloneToast({ theme }).toast
export const baseurl = process.env.NEXT_PUBLIC_BACKEND_URL

export const axiosInstance = axios.create({
  baseURL: `${baseurl}/api/v1/logicexams/`,
  headers: {
    'Access-Control-Allow-Headers':
      'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type',
    'Access-Control-Allow-Origin': '*',
  },
})

export const getSocket = () => {
  const user = getUser()
  if (user && (user.token || user.candidate)) {
    return io(process.env.NEXT_PUBLIC_BACKEND_WS_URL as string, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      autoConnect: true,
      // transports: ['polling', 'websocket'],
      transports: ['websocket'],
      // withCredentials: true,
      auth: {
        token: user.token,
        organization_id: user.admin?.organization_id || undefined,
        candidate_id: user.candidate?.id || undefined,
      },
    })
  }
  // return null;
  return io(process.env.NEXT_PUBLIC_BACKEND_WS_URL as string)
}

export const login = (userData?: any) => {
  let user = userData
  if (!user) {
    // call for re-authentication
    user = getUser()
  }

  if (user) {
    setToken(user.token)
    setCandidate(user.candidate)
  }
}

export const logout = (userType: 'Admin' | 'Candidate') => {
  if (userType === 'Admin') {
    stores.auth.logoutAdmin()
    localStorage.removeItem(L_TOKEN)
    localforage.clear().then(() => {})
  } else {
    stores.candidate.logoutCandidate()
    localforage.clear().then(() => {})
    localStorage.removeItem(CANDIDATE_ID)
  }

  window.location.href = '/login'
}

export const handleErrorResponse = (error: any) => {
  if (error.response) {
    if (error.response.status === 500) {
      error.message = 'Network error please try again'
    } else error.message = error?.response?.data?.message || error.message
  } else error.message = error.message || 'Error occurred'

  const err = Array.isArray(error.message)
    ? error.message.join(', ')
    : error.message

  customToast.closeAll()
  // add a toast or do something with the error
  customToast({
    title: err,
    description: '',
    status: 'error',
    duration: 4000,
    isClosable: true,
  })
}

export const handleSuccessResponse = (message: string) => {
  customToast.closeAll()
  // add a toast or do something with the error
  customToast({
    title: message,
    description: '',
    status: 'success',
    isClosable: true,
  })
}

export function redirectTo(
  destination: any,
  { res, status }: { res: any; status?: any }
) {
  console.log('i am going here ', destination)
  if (res) {
    res.writeHead(status || 302, { Location: destination })
    res.end()
  } else {
    if (destination[0] === '/' && destination[1] !== '/') {
      Router.push(destination)
    } else {
      if (typeof window !== 'undefined') window.location = destination
    }
  }
}

export function authWrapper(next: any) {
  return async function auth(ctx: any) {
    const user = getUser()

    if (!user) {
      return {
        redirect: {
          destination: '/login',
          permanent: false,
        },
      }
    }

    const props = { user }

    return typeof next === 'function' ? next(ctx, props) : { props }
  }
}

export const validate = (field: any, pattern: any) => {
  const parts = /\/(.*)\/(.*)/.exec(pattern) || []
  let restoredRegex = new RegExp(parts[1], parts[2])
  if (restoredRegex.test(field)) return true
  return false
}

export const setToken = (token: string) => {
  axiosInstance.defaults.headers.common['token'] = token

  if (typeof window !== 'undefined') {
    localStorage.setItem(L_TOKEN, token)
  }
}

export const setCandidate = (candidate: any) => {
  if (!candidate || candidate === 'null') return

  axiosInstance.defaults.headers.common['candidate_id'] = candidate.id
  // Add to token, incase of production, candidate_id is not sent, always undefined, don't know why,
  // so spent some times to find out the solution, and this is the solution
  axiosInstance.defaults.headers.common['token'] = candidate.id

  if (typeof window !== 'undefined') {
    localStorage.setItem(CANDIDATE_ID, JSON.stringify(candidate))
    stores.candidate.setCandidate(candidate)
  }
}

const tokens: any = {}

export function parseJwt(token: any) {
  if (!token) return
  if (tokens[token]) return tokens[token]

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      // Buffer.from(base64, 'base64')
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  const result = JSON.parse(jsonPayload)
  tokens[token] = result

  return result
}

export const whiteLabelApps = {
  stlouisnpbida: {},
  ids: ['bd5fc2db-3378-45e7-a706-12c9013806f7'],
  subdomains: [''],
}

export function getUser() {
  let user
  let ctoken
  let candidate
  let isExpired: boolean = false

  if (typeof window !== 'undefined') {
    ctoken = localStorage.getItem(L_TOKEN)
    candidate = localStorage.getItem(CANDIDATE_ID)
  }

  if (ctoken !== 'null') {
    user = parseJwt(ctoken)
  }

  isExpired = user && user.exp && user.exp < Date.now() / 1000

  return {
    admin: user,
    candidate: JSON.parse(candidate || 'null'),
    token: ctoken,
    isExpired,
  }
}

export const getQueryString = (params?: any) => {
  const paramsFilters = omitBy(params, isNil)
  const query = Object.keys(paramsFilters)
    .map(
      k => encodeURIComponent(k) + '=' + encodeURIComponent(paramsFilters[k])
    )
    .join('&')
  return query
}

export const stringSearch = (val: string, string: string) =>
  string && string.toLowerCase().search(val.toLowerCase()) !== -1

export function isInViewport(element: any) {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const isAdmin = () => {
  const user = getUser()
  return user.admin && ADMINS_ID.includes(user.admin.roleId)
}

type PagesDataKeysType = keyof typeof PAGES_DATA
type PagesDataValuesType = typeof PAGES_DATA[PagesDataKeysType]
type NestedObjectKeys = DotNestedKeys<PagesDataValuesType>

export interface IPermissionNeeded {
  name: NestedObjectKeys
  can: 'add' | 'edit' | 'delete' | 'visible'
}

export const hasPermission = (
  permissionNeeded: IPermissionNeeded,
  userPermission: IUserPermission[] | undefined
) => {
  if (userPermission?.length === 0 || !permissionNeeded) return true
  // check permission based on the params
  const { name, can } = permissionNeeded
  const pagePermission = userPermission?.find(
    permission => permission.name === name
  )
  if (!pagePermission) return false
  return pagePermission[can]
}

export const getDifficultyTextColor = (difficulty: string) => {
  switch (difficulty) {
    case 'easy':
      return 'green'
    case 'medium':
      return 'orange'
    case 'hard':
      return 'red'
    default:
      return 'black'
  }
}

export const getQuestionTypeInFull = (type: string) => {
  switch (type) {
    case 'multiple_choice':
      return 'Multiple Choice'
    case 'true_false':
      return 'True/False'
    case 'fill_in_the_blank':
      return 'Fill in the Blank'
    case 'short_answer':
      return 'Short Answer'
    case 'match_the_column':
      return 'Match the Column'
    case 'multiple_response':
      return 'Multiple Response'
    case 'passage':
      return 'Passage'
    case 'essay':
      return 'Essay'
    default:
      return 'Unknown'
  }
}

export const getStatusTextColor = (status: string) => {
  switch (status) {
    case 'active':
      return '#00BC62'
    case 'pending':
      return 'orange'
    case 'disabled':
      return 'black'
    default:
      return 'black'
  }
}

export const ngnCurrencyFormatter = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  }).format(value)
}

export function truncateText(str: any, max: number) {
  return str.length > max ? str.substr(0, max - 1) + '…' : str
}

export const exportToExcel = (data: any[], fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(data)
  const wb = {
    Sheets: {
      data: ws,
    },
    SheetNames: ['data'],
  }

  const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([eb], { type: EXCEL_TYPE })
  saveAs(blob, fileName + EXCEL_EXTENSION)
}
