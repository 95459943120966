import {
  action,
  makeAutoObservable,
  makeObservable,
  observable,
  toJS,
} from 'mobx'
import { hydrateStore, makePersistable } from 'mobx-persist-store'

interface IExam {
  name?: string
  mark_obtainable?: string
  pass_mark?: string
  date?: string
  total_questions?: string
  duration?: number
  instructions?: string
  start_date?: string
  end_date?: string
  enable_result?: boolean
  enable_proctoring?: boolean
  enable_shuffle_questions?: boolean
}

export class ExamAdmin {
  exam: IExam = {}
  candidateSelection = {
    mode: 'single', // single or all
    groupId: '',
    total: 0,
  }
  questionSelection = {
    categoryIds: [] as string[],
    total: 0,
  }
  public selectedQuestionIds: string[] = []
  public questions: IQuestion[] = []
  public questionCategories: QuestionCategory[] = []
  public candidates: ICandidate[] = []

  constructor() {
    makeAutoObservable(this)

    // makePersistable(this, {
    //     name: 'ExamAdmin',
    //     properties: ['questions'],

    //   })
  }

  public setSelectedQuestionIds(ids: string[]) {
    this.selectedQuestionIds = ids
  }

  public resetSelectedQuestionIds() {
    this.selectedQuestionIds = []
  }

  public setCandidateSelection(mode: string, groupId: string, total = 0) {
    this.candidateSelection = { mode, groupId, total }
  }

  public setQuestionSelection(categoryId: string, total = 0) {
    const foundCategory = this.questionSelection.categoryIds.find(
      id => id === categoryId
    )
    if (!foundCategory) {
      this.questionSelection.categoryIds.push(categoryId)
      this.questionSelection.total += total
      return
    }
    this.questionSelection.categoryIds =
      this.questionSelection.categoryIds.filter(id => id !== categoryId)
    this.questionSelection.total -= total
  }

  public resetQuestionSelection() {
    this.questionSelection = {
      categoryIds: [],
      total: 0,
    }
  }

  public resetCandidates() {
    this.candidates = []
  }

  public addExam(param: IExam) {
    this.exam = param
  }

  public getExam() {
    return {
      ...toJS(this.exam),
      questions: this.questions.map(q => q.id),
      candidates: this.candidates.map(c => c.id),
    }
  }

  public resetExam() {
    this.exam = {}
    this.questions = []
    this.candidates = []
    this.questionCategories = []
    this.candidateSelection = {
      mode: 'single',
      groupId: '',
      total: 0,
    }
    this.questionSelection = {
      categoryIds: [],
      total: 0,
    }
  }

  public addQuestions(params: IQuestion[] | any[]) {
    this.questions = params
  }

  public addQuestion(question: IQuestion) {
    const foundQuestion = this.questions.find(q => q.id === question.id)
    if (!foundQuestion) {
      this.questions.push(question)
      return
    }
    this.questions = this.questions.filter(q => q.id !== question.id)
  }

  public removeQuestion(question: IQuestion) {
    this.questions = this.questions.filter(q => q.id !== question.id)
  }

  public getQuestions() {
    return toJS(this.questions)
  }

  public addQuestionCategories(params: QuestionCategory[]) {
    this.questionCategories = params
  }

  public addQuestionCategory(questionCategory: QuestionCategory) {
    const foundQuestionCategory = this.questionCategories.find(
      q => q.id === questionCategory.id
    )
    if (!foundQuestionCategory) {
      this.questionCategories.push(questionCategory)
      return
    }
    this.questionCategories = this.questionCategories.filter(
      q => q.id !== questionCategory.id
    )
  }

  public removeQuestionCategory(questionCategory: QuestionCategory) {
    this.questionCategories = this.questionCategories.filter(
      q => q.id !== questionCategory.id
    )
  }

  public getQuestionCategories() {
    return toJS(this.questionCategories)
  }

  public getQuestionCategoryQuestionCount() {
    return this.questionCategories.reduce(
      (acc, curr) => acc + curr.question_count,
      0
    )
  }

  public addCandidate(candidate: ICandidate) {
    const foundCandidate = this.candidates.find(c => c.id === candidate.id)
    if (!foundCandidate) {
      this.candidates.push(candidate)
      return
    }
    this.candidates = this.candidates.filter(c => c.id !== candidate.id)
  }

  public getCandidates() {
    return toJS(this.candidates)
  }

  hydrate = async () => {
    await hydrateStore(this)
  }
}
