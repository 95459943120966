import React from 'react'
import { useRouter } from 'next/router'
import { AppProps as NextAppProps } from 'next/app'
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import localforage from 'localforage'

import Layout from '@/components/Layout'
import { RouteGuard } from '@/components/RouteGuard'
import { axiosInstance, login } from '@/lib/helpers'

import { hydrateStores, stores, StoresProvider } from '../stores'
import { LoadingProgressProvider } from '../services'
import '../theme/styles.css'
import theme from '../theme'
import { queryClient } from '@/lib/react-query/queryClient'
import { APP_NAME } from '@/lib/constants/appConstants'
import { NextPage, NextPageContext } from 'next'
import Meta from '@/components/Meta'
const { ToastContainer } = createStandaloneToast()

const customToast = createStandaloneToast({ theme }).toast

type AppProps<P = any> = {
  pageProps: P
} & Omit<NextAppProps<P>, 'pageProps'>

function MainApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const [ready, setReady] = React.useState(false)

  const startApp = React.useCallback(async () => {
    await hydrateStores()
    if (pageProps && pageProps.config) {
      stores.config.setSiteData(pageProps.config)
    }
    login()

    try {
      const { subdomain, domain } = getHost()

      if (
        subdomain &&
        subdomain !== 'app' &&
        !stores.organization.getOrganization()
      ) {
        const { data } = await axiosInstance.get(
          `/organization/lookup_by_suborcustom_domain/${subdomain}`
        )
        stores.organization.setOrganization(data.data?.organization)
        stores.organization.setOrganizations(data.data?.organizations)
      }

      if (!subdomain && domain && !stores.organization.getOrganization()) {
        const { data } = await axiosInstance.get(
          `/organization/lookup_by_suborcustom_domain/${domain}`
        )
        stores.organization.setOrganization(data.data?.organization)
        stores.organization.setOrganizations(data.data?.organizations)
      }
    } catch (e) {}

    setReady(true)
  }, [])

  const getHost = React.useCallback(() => {
    const host = window.location.host
    const domain = host.split('.')
    let subdomain = ''

    if (host.includes(APP_NAME.toLowerCase())) {
      subdomain = domain[0]
    }
    return { subdomain, domain: host }
  }, [])

  React.useEffect(() => {
    startApp()
  }, [startApp])

  React.useEffect(() => {
    axiosInstance.interceptors.response.use(
      res => {
        return res
      },
      err => {
        if (err.response?.status === 401) {
          customToast.closeAll()
          customToast({
            title: 'Session Expired',
            description: 'Your session has expired. Please login again.',
            status: 'error',
            variant: 'top-accent',
            isClosable: true,
          })

          // For admin
          localStorage.removeItem('_l_exa')
          localforage.clear().then(() => {})

          // For candidate
          localStorage.removeItem('_c_id')
          stores.candidate.logoutCandidate()

          document.cookie =
            'authtoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

          router.push({
            pathname: '/login',
            query: { returnUrl: router.asPath },
          })
        }
        return Promise.reject(err)
      }
    )
  }, [])

  return (
    <>
      <Meta />
      <StoresProvider>
        {ready && (
          <ChakraProvider resetCSS theme={theme}>
            <QueryClientProvider client={queryClient}>
              <LoadingProgressProvider>
                <Layout>
                  <RouteGuard>
                    {/* @ts-ignore */}
                    <Component {...pageProps} />
                  </RouteGuard>
                </Layout>
              </LoadingProgressProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </ChakraProvider>
        )}
        <ToastContainer />
      </StoresProvider>
    </>
  )
}

MainApp.getInitialProps = async ({
  Component,
  ctx,
}: {
  Component: NextPage
  ctx: NextPageContext
}) => {
  if (typeof window === 'undefined') {
    const { data } = await axiosInstance
      .get(`/config`)
      .catch(err => ({ data: null }))

    if (ctx.res) {
      ctx.res.setHeader(
        'Cache-Control',
        'public, s-maxage=10, stale-while-revalidate=59'
      )
    }
    return {
      pageProps: {
        config: data?.data,
      },
    }
  }

  return {}
}

export default MainApp
