import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
// @ts-ignore
import TawkTo from 'tawkto-react'
import * as gtag from '@/lib/gtag'

import { getUser } from '@/lib/helpers'
import { stores } from '../stores'

let hostname: string
if (typeof window !== 'undefined') {
  hostname = window.location.host.split('.')[0]
}

export { RouteGuard }

function RouteGuard({ children }: { children: any }) {
  const router = useRouter()
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    // on initial load - run auth check
    authCheck(router.asPath)

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false)
    // router.events.on('routeChangeStart', hideContent)

    // on route change complete - run auth check
    router.events.on('routeChangeComplete', authCheck)

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off('routeChangeStart', hideContent)
      router.events.off('routeChangeComplete', authCheck)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function authCheck(url: string) {
    // redirect to login page if accessing a private page and not logged in
    const publicPaths = [
      '/',
      '/login',
      '/signup',
      '/lanversion',
      '/privacy',
      '/terms',
      '/forgot-password',
      '/reset-password',
      '/sitemap.xml',
      '/partner',
    ]
    const path = url.split('?')[0]

    // checker whether is from admin app and not user or candidate side
    if (hostname && hostname === 'app') {
      if (!getUser().admin && !publicPaths.includes(path)) {
        setAuthorized(false)
        router.push({
          pathname: '/login',
          query: { returnUrl: router.asPath },
        })
      } else {
        setAuthorized(true)
      }

      // Add TawkTo
      new TawkTo(
        process.env.NEXT_PUBLIC_TAWKTO_PROPERTY_ID,
        process.env.NEXT_PUBLIC_TAWKTO_WIDGET_ID
      )
    } else {
      const pathname = router.asPath.includes('_sites/[site]/')
        ? router.asPath.replaceAll('_sites/[site]/', '')
        : router.asPath
      // this will run for user or candidate app
      if (!getUser().candidate && !publicPaths.includes(path)) {
        setAuthorized(false)
        router.push({
          pathname: '/login',
          query: { returnUrl: pathname },
        })
      } else {
        setAuthorized(true)
      }
    }

    if (window && process.env.NODE_ENV === 'production') {
      gtag.pageview(url)
      gtag.event({
        action: 'view_item',
        category: 'engagement',
        label: stores.auth.currentOrganization
          ? stores.auth.currentOrganization.email
          : 'no_email',
      })
    }
  }

  //   if (!authorized) return <div>loading...</div>
  return authorized && children
}
