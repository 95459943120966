import { MdOpacity } from 'react-icons/md'

const table = {
  variants: {
    'lg-table-1': (props: any) => ({
      thead: {
        h: '3.938rem',
        boxShadow: 'md',
      },
    }),
    'lg-table-2': (props: any) => ({
      thead: {
        h: '2.625rem',
      },
      th: {
        bg: props.colorMode === 'light' ? '#f2f2f2' : 'primaryDark',
      },
    }),
    'lg-selectable': (props: any) => ({
      thead: {
        h: '2.625rem',
      },
      th: {
        bg: props.colorMode === 'light' ? '#f2f2f2' : 'primaryDark',
        border: 'none',
        h: '2.625rem',
      },
      tr: {
        border: '0.5px solid rgba(0,0,0, 0.1)',
        cursor: 'pointer',
        h: '5rem',
        _hover: {
          bg: 'secondaryLight',
        },
      },
    }),
    'activity-table': (props: any) => ({
      thead: {
        h: '3.938rem',
      },
      th: {
        bg: props.colorMode === 'light' ? '#f2f2f2' : 'primaryDark',
        border: 'none',
        h: '3.938rem',
      },
      td: {
        border: 'none',
      },
    }),

    'lg-table': (props: any) => ({
      table: {
        bg: props.colorMode === 'light' ? 'white' : 'gray.800',
      },
      thead: {
        h: '3.938rem',
      },
      tbody: {
        h: '3.38rem',
      },
      th: {
        bg: props.colorMode === 'light' ? '#f2f2f2' : 'primaryDark',
        border: 'none',
        h: '3.938rem',
      },
      tr: {
        border: '0.5px solid rgba(0,0,0, 0.1)',
      },
    }),
    'notification-table': (props: any) => ({
      thead: {
        h: '3.938rem',
        tr: {
          h: '3.98rem',
        },
      },
      tbody: {
        h: '3.38rem',
      },
      th: {
        bg: props.colorMode === 'light' ? '#fff' : 'primaryDark',
        border: 'none',
        h: '3.938rem',
      },
      tr: {
        border: '0.5px solid rgba(0,0,0, 0.1)',
        cursor: 'pointer',
      },
      td: {},
    }),
  },
}

export default table
