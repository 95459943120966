import Link, { LinkProps } from 'next/link'
import {
  Button,
  ButtonProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Flex,
} from '@chakra-ui/react'

type ChakraAndNextProps = ButtonProps & LinkProps

export function CustomLinkButton({
  href,
  children,
  prefetch = true,
  ...props
}: ChakraAndNextProps) {
  return (
    <Link href={href} passHref prefetch={prefetch}>
      <Button as="a" {...props}>
        {children}
      </Button>
    </Link>
  )
}

type ChakraLinkAndNextProps = ChakraLinkProps & LinkProps

export const CustomLink = ({
  href,
  children,
  ...props
}: ChakraLinkAndNextProps) => {
  return (
    <Link href={href} passHref>
      <ChakraLink
        _hover={{ underLine: 'none' }}
        _focus={{ boxShadow: 'none' }}
        {...props}
      >
        {children}
      </ChakraLink>
    </Link>
  )
}
