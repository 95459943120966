import React from 'react'

import './_hydration'
import { UI } from './ui'
import { Auth } from './auth'
import { Config } from './config'
import { Organization } from './organization'
import { ExamAdmin } from './examadmin'
import { Candidate } from './candidate'
import { Exam } from './exam'

export const stores = {
  ui: new UI(),
  auth: new Auth(),
  candidate: new Candidate(),
  config: new Config(),
  organization: new Organization(),
  exam: new Exam(),
  examadmin: new ExamAdmin(),
}

type ContextStores = typeof stores

const storeContext = React.createContext<ContextStores>(stores)
export const StoresProvider = ({ children }: any) => (
  <storeContext.Provider value={stores}>{children}</storeContext.Provider>
)

export const useStores = (): ContextStores => React.useContext(storeContext)

export const hydrateStores = async (): PVoid => {
  for (const key in stores) {
    if (Object.prototype.hasOwnProperty.call(stores, key)) {
      const s = (stores as Stores)[key]

      if (s.hydrate) {
        await s.hydrate()
      }
    }
  }
}
