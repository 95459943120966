import LEColorConstants from '@/lib/constants/colorConstants'

export default {
  brand: {
    primary: {
      '50': '#eff9ff',
      '100': '#bee6ff',
      '200': '#82ceff',
      '300': '#30aeff',
      '400': '#0099fb',
      '500': '#0081d4',
      '600': '#006db3',
      '700': '#005890',
      '800': '#004a7a',
      '900': '#003659',
    },
    secondary: {
      '50': '#fff6ef',
      '100': '#ffd9be',
      '200': '#ffb681',
      '300': '#ff842c',
      '400': '#f56a06',
      '500': '#cf5905',
      '600': '#af4b04',
      '700': '#8d3d03',
      '800': '#773303',
      '900': '#562502',
    },
  },
  transparent: 'transparent',
  black: '#16161D',
  lightBlack: '#45454a',
  white: '#FFF',
  blue: '#006EF6',
  green: '#00BE85',
  blacktext: '#1B1C1E',
  blackRgba: 'rgba(27, 28, 30, 0.6)',
  primary: LEColorConstants.primary,
  primaryDark: LEColorConstants.primaryDark,
  primaryLight: LEColorConstants.primaryLight,
  primaryRgba: LEColorConstants.primaryRgba,
  secondary: LEColorConstants.secondary,
  secondaryDark: LEColorConstants.secondaryDark,
  secondaryLight: LEColorConstants.secondaryLight,
  secondaryLighter: LEColorConstants.secondaryLighter,
  lightGray: '#F2F2F2',
  lineColor: '#C4C4C4',
  tabBtnColor: ' #009BFF',
  tabCustomInputBg: '#E5E5E5',
  colorCheck: '#03ED7D',
  examIconColor: '#FF6E06',
  switchColor: '#68EBAC',
}
