export const APP_NAME = 'TestNotion'
export const APP_DESCRIPTION =
  'The one tool to prepare, conduct, and auto-grade tests. Easy and fast.'
export const APP_QUESTION_TYPES = {
  MC: 'multiple choice',
  MR: 'multiple response',
  PRQ: 'Passage & Question',
  TF: 'True or False',
  // FINTB: 'fill in the blanks'
}
