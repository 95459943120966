const LEColorConstants = {
  // Primary
  greyPrimary: '#fafafb',
  grey: '#F5F8F9',
  greyLight: '#ededed',
  greySemiLight: '#E5E5E5',
  white: '#FFFFFF',
  black: '#171725',
  greyDarker: '#4A5568',
  greyDark: '#92929D',
  greyLighter: '#A1A2AB',
  greyLightest: '#F2F2F2',
  primary: '#009BFF',
  primaryDark: '#0077c4',
  primaryLight: '#b1e0ff',
  primaryRgba: 'rgba(0, 155, 255, 0.1)',
  colorCheck: '#03ED7D',
  customDomain: '#A7FFBD',
  lightIndigo: '#EEF4FF',
  lightStep: '#E5F2FD',
  lightStepBg: '#FCFCFC',

  // Secondary
  secondary: '#FF6E06',
  secondaryDark: '#b74c00',
  secondaryLight: '#ffd5b7',
  secondaryLighter: '#EFF0FF',
  secondaryRgba: 'rgba(255, 110, 6, 0.15)',
  textLight: '#1B1C1E',
}

export const checkColor = LEColorConstants.colorCheck

export const basicTextColor = { light: '#1B1C1E', dark: 'white' }
export const whiteTextColor = { light: 'white', dark: '#1B1C1E' }
export const lightBgTheme = {
  light: LEColorConstants.lightStep,
  dark: LEColorConstants.greyDarker,
}

export const lightBgStep = {
  light: LEColorConstants.lightStepBg,
  dark: LEColorConstants.textLight,
}
export const brandPrimayColor = {
  light: LEColorConstants.primary,
  dark: LEColorConstants.primaryDark,
}
export const brandSecondaryColor = {
  light: LEColorConstants.secondary,
  dark: LEColorConstants.secondaryDark,
}
export const basicTextRgba = { light: 'blackRgba', dark: 'white' }

export const authBGColor: Q = {
  light: '#FCFCFC',
  dark: '#191919',
}

export const borderThemeColor: Q = {
  light: LEColorConstants.greyDarker,
  dark: LEColorConstants.greyLight,
}

export const borderErrorThemeColor: Q = {
  light: '#E53E3E',
  dark: '#FC8181',
}
export const borderSuccessThemeColor: Q = {
  light: '#E2E8F0', // gray.200
  dark: '#718096', // gray.500
}

export const borderTransparentThemeColor: Q = {
  light: 'rgba(0,0,0,0.10)',
  dark: 'rgba(255, 255, 255, 0.10)',
}
export const dashboardColor: Q = { light: '#F5F8F9', dark: '#333' }

export const cardThemeColor: Q = {
  light: LEColorConstants.white,
  dark: LEColorConstants.primaryRgba,
}

export const primaryThemeColor: Q = {
  light: LEColorConstants.primary,
  dark: LEColorConstants.primaryDark,
}

export const sidePanelThemeColor: Q = {
  light: LEColorConstants.greySemiLight,
  dark: LEColorConstants.greyDarker,
}
export const sidePanelThemeColor2: Q = {
  light: LEColorConstants.greyLightest,
  dark: LEColorConstants.greyDarker,
}

export const secondaryThemeColor: Q = {
  light: LEColorConstants.secondary,
  dark: LEColorConstants.secondaryDark,
}

export const basicThemeColor: Q = {
  light: LEColorConstants.primary,
  dark: LEColorConstants.white,
}

export const bgThemeColor: Q = {
  light: LEColorConstants.white,
  dark: 'gray.800',
}

export const bgGrayThemeColor: Q = {
  light: LEColorConstants.greyLightest,
  dark: 'gray.800',
}

export const sideBarBGThemeColor: Q = {
  light: '#3F3D56',
  dark: 'gray.800',
}

export const sideBarThemeColor: Q = {
  light: LEColorConstants.white,
  dark: 'gray.800',
}

export const transparentBg: Q = {
  light: "url('/elips.svg')",
  dark: LEColorConstants.primaryRgba,
}
export const themeColor: Q = { light: 'black', dark: 'white' }

export default LEColorConstants
export const basicBgColorRight = { light: '#f2f2f2', dark: 'lightBlack' }
export const basicBgColorLeft = {
  light: 'rgba(0, 155, 255, 0.1)',
  dark: 'brand.primary.900',
}

export const sidebarBg: Q = {
  light: '#68EBAC',
  dark: LEColorConstants.greyDarker,
}

export const grayBgColor = { light: '#f2f2f2', dark: 'gray.800' }
export const indigoBgColor = {
  light: '#EEF4FF',
  dark: LEColorConstants.greyDarker,
}

export const customDomainColor = {
  light: LEColorConstants.customDomain,
  dark: LEColorConstants.primary,
}

export const customTextLightColor = {
  light: LEColorConstants.textLight,
  dark: LEColorConstants.white,
}
