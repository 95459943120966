import React, { useEffect, useState } from 'react'
import { bgThemeColor } from '@/lib/constants/colorConstants'
import { Box, Text, useColorMode, Flex } from '@chakra-ui/react'
import CustomButton from '../Button'
import { CustomLink } from '../Link'

export default function Cookies() {
  const [isCookie, setIsCookie] = useState<boolean>(true)
  const { colorMode } = useColorMode()

  const handleAcceptCookie = () => {
    let date = new Date()
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000 + 150000))
    const expires = 'expires= ' + date.toUTCString()

    document.cookie = `cookieName=newCookie${new Date().toISOString()}; expires=${expires}; path=/`
    setIsCookie(true)
  }

  useEffect(() => {
    let decodedCookie = decodeURIComponent(document.cookie)
    const cArr = decodedCookie.split(';')
    const name = 'cookieName' + '='
    let res: any
    cArr.forEach(val => {
      if (val.indexOf(name) === 0 || val.includes(name))
        res = val.substring(name.length)
      if (res) setIsCookie(true)
      else setIsCookie(false)
    })
  }, [isCookie])

  if (!isCookie)
    return (
      <Box
        w="100%"
        padding=" 20px 26px"
        boxShadow="0 10px 10px 0 #acabab4d"
        borderRadius=" 6px"
        position="fixed"
        zIndex={99999}
        bottom={[0]}
        left={[0]}
        background={bgThemeColor[colorMode]}
        borderTop="3px solid #009BFF"
      >
        <Flex justifyContent="space-between" width="full" alignItems="center">
          <Text
            fontSize={['0.8rem', '1rem']}
            lineHeight={['15px', '25px']}
            fontWeight={400}
          >
            This website uses cookies to ensure your get best experience on our
            website. &nbsp;
            <CustomLink
              target="_blank"
              href="https://www.cookiesandyou.com/"
              color="primary"
            >
              Learn More
            </CustomLink>
          </Text>
          <Box textAlign="right">
            <CustomButton
              maxW="6rem"
              maxH="2.5rem"
              borderRadius={'5px'}
              onClick={handleAcceptCookie}
              fontSize={['0.8rem', '1rem']}
              fontWeight={'medium'}
            >
              Got it!
            </CustomButton>
          </Box>
        </Flex>
      </Box>
    )
  return <></>
}
