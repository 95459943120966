import { setToken } from '@/lib/helpers'
import { createOrganizationApiMethod } from '@/services/api/organization'
import { makeAutoObservable } from 'mobx'
import { hydrateStore } from 'mobx-persist-store'
import { stores } from '.'

interface IOrg {
  id?: string
  category?: string
  name?: string
  domain?: string
  subdomain?: string
  custom_domain?: string
  email?: string
  phone?: string
  avatar?: string
  address?: string
  country?: string
  state?: string
  city?: string
  postal_code?: string
  owner_id?: string
  current_period_ends?: Date | null
}
export class Organization {
  private org: IOrg | null = null
  private organizations: IOrg[] = []

  public async createOrganization(params: any) {
    try {
      const res = await createOrganizationApiMethod(params)

      console.log(res.data.data)

      const token = res.data.data.token
      const admin = res.data.data.admin
      const organization = res.data.data.organization
      const role = res.data.data.role

      document.cookie = 'authtoken=' + token
      setToken(token)

      stores.auth.setCurrentOrganization(res.data.data)
      stores.auth.setCurrentAdmin({ ...admin, role })
      stores.auth.setCurrentOrganization(organization)
    } catch (error) {
      throw error
    }
  }

  public setOrganization(org: IOrg) {
    this.org = org
  }

  public setOrganizations(orgs: IOrg[]) {
    this.organizations = orgs
  }

  public getOrganizations() {
    return this.organizations
  }

  public getOrganization() {
    return this.org
  }

  constructor() {
    makeAutoObservable(this)
  }

  hydrate = async () => {
    await hydrateStore(this)
  }
}
