import { makeAutoObservable } from 'mobx'
import { hydrateStore, makePersistable } from 'mobx-persist-store'

export class OrganizationModel {
  public id?: string = ''
  public category?: string = ''
  public name?: string = ''
  public subdomain?: string = ''
  public custom_domain?: string = ''
  public email?: string = ''
  public phone?: string = ''
  public avatar?: string = ''
  public address?: string = ''
  public country?: string = ''
  public state?: string = ''
  public city?: string = ''
  public postal_code?: string = ''
  public slogan?: string = ''
  public plan_id?: string = ''
  public owner_id?: string = ''
  public current_period_ends?: Date | null = null
  public meta?: any = null

  constructor(params: any) {
    this.id = params.id
    this.name = params.name
    this.category = params.category
    this.subdomain = params.subdomain
    this.custom_domain = params.custom_domain
    this.email = params.email
    this.phone = params.phone
    this.avatar = params.avatar
    this.address = params.address
    this.country = params.country
    this.state = params.state
    this.city = params.city
    this.postal_code = params.postal_code
    this.slogan = params.slogan
    this.plan_id = params.plan_id
    this.owner_id = params.owner_id
    this.current_period_ends = params.current_period_ends
    this.meta = params.meta

    makeAutoObservable(this)

    // makePersistable(this, {
    //     name: 'Organization',
    //     properties: ['id', 'name', 'subdomain', 'custom_domain', 'email', 'phone', 'avatar', 'address', 'country'],
    // })
  }

  hydrate = async () => {
    await hydrateStore(this)
  }
}
