import { makeAutoObservable } from 'mobx'
import { hydrateStore } from 'mobx-persist-store'

export class Candidate {
  private candidate: ICandidate | null = null

  public getCandidate() {
    return this.candidate
  }

  public setCandidate(candidate: ICandidate) {
    this.candidate = candidate
  }

  public logoutCandidate() {
    this.candidate = null
  }

  constructor() {
    makeAutoObservable(this)
  }

  hydrate = async () => {
    await hydrateStore(this)
  }
}
