import { makeAutoObservable } from 'mobx'
import { hydrateStore } from 'mobx-persist-store'

interface IConfig {
  config: {
    units: {
      unit_rate_to_ngn: number
      payg_unit_per_exam: number
      standard_unit_per_exam: number
      proctoring_unit_rate_minute: number
      special_packages: {
        unit: number
        amount_in_ngn: number
      }[]
    }
  }
  plans: {
    id: string
    name: string
    description: string
    price_in_ngn: number
    usage_limit: number
  }[]
  sitePages: []
}
export class Config {
  private siteData: IConfig = {
    config: {
      units: {
        payg_unit_per_exam: 0,
        standard_unit_per_exam: 0,
        unit_rate_to_ngn: 0,
        proctoring_unit_rate_minute: 0,
        special_packages: [],
      },
    },
    plans: [],
    sitePages: [],
  }

  public setSiteData(data: any) {
    if (data) {
      this.siteData = data
    }
  }

  public getSiteData() {
    return this.siteData
  }

  public getUnits() {
    return this.siteData.config.units
  }

  public getSitePages() {
    return this.siteData.sitePages
  }

  public getPlans() {
    return this.siteData.plans
  }

  public getConfig() {
    return this.siteData.config
  }

  constructor() {
    makeAutoObservable(this)
  }

  hydrate = async () => {
    await hydrateStore(this)
  }
}
