const Modal = {
  sizes: {
    goodone: {
      h: '67.625rem',
      w: '54.813rem',
    },
  },
}

export default Modal
