import { makeAutoObservable } from 'mobx'
import { hydrateStore, makePersistable } from 'mobx-persist-store'

export class UI implements IStore {
  appLaunches = 0
  isSystemAppearance = true
  isFirstOpen = true

  setAppFirstOpen = (v: boolean): void => {
    this.isFirstOpen = v
  }

  incAppLaunces = (v = 1): void => {
    this.appLaunches += v
  }

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'UI',
      properties: ['appLaunches', 'isSystemAppearance', 'isFirstOpen'],
    })
  }

  hydrate = async (): PVoid => {
    await hydrateStore(this)
  }
}
