import localForage from 'localforage'
import { configurePersistable } from 'mobx-persist-store'

const SSR = typeof window === 'undefined'

// localForage configuration
configurePersistable({
  // debugMode: __DEV__,
  storage: SSR ? undefined : localForage,
})
